/** @jsxImportSource @emotion/react */
import { PlusCircleIcon, PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import "twin.macro";
import { useAuth } from "../auth/AuthContext";
import { useNewCountCandidates } from "../candidates/CandidateAPI";
import { useUnreadMessageCount } from "../chat/ConversationAPI";
import { useJobAdCountByStatus } from "../job-ads/JobAdAPI";
import { useOrganizationQuery } from "../settings/OrganizationAPI";
import { LinkButton, PrimaryButton } from "../shared/Button";
import { Skeleton } from "../shared/Skeleton";

export const CardDashboard = ({ data, status, tittle, logo, link, ...props }) => {
  return (
    <div
      tw="relative flex items-center justify-center flex-col gap-4 w-full min-h-15 px-6 pt-24 pb-2 border text-sm font-medium rounded-md shadow-md"
      {...props}
    >
      {logo}
      <span tw="text-xl font-normal text-gray-900">{tittle}</span>
      {status === "loading" && (
        <span tw="text-2xl font-extrabold text-blue-600">
          <Skeleton />
        </span>
      )}
      {status === "success" && <span tw="text-2xl font-extrabold text-blue-600">{data}</span>}
      {link}
    </div>
  );
};

export const Dashboard = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // Get unread chat messages
  const { status: unreadMessagesNumberStatus, data: unreadMessagesNumber } =
    useUnreadMessageCount();

  //Get the new candidates within the previous month and now
  let dateBefore = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 7
  ).toISOString();
  const { status: newCountCandidatesStatus, data: newCountCandidates } = useNewCountCandidates({
    date: dateBefore,
  });

  // Get jobAds with paging
  const { status: dataCountByStatusStatus, data: dataCountByStatus } = useJobAdCountByStatus();
  // Get organization PREMIUM or not
  const { user } = useAuth();
  const { status, data } = useOrganizationQuery();

  //Set the organization type in the local storage
  status === "success" && localStorage.setItem("organizationType", data.organizationType);
  return (
    <div tw="flex flex-grow flex-col bg-white rounded-md p-4">
      {data?.organizationType !== "SCHOOL" && (
        <div tw="flex justify-start flex-wrap gap-4 mb-16">
          {process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" &&
            <PrimaryButton
              as={Link}
              to={{
                pathname: `/job-ads`,
                search: location.search,
              }}
            >
              <PlusIcon aria-hidden="true" />
              {t("home.createAd")}
            </PrimaryButton>
          }
          {(process.env.REACT_APP_APPLICATION_DOMAIN === "hupso" || process.env.REACT_APP_APPLICATION_DOMAIN === "phdavenue") && (
            <PrimaryButton
              as={Link}
              to={{
                pathname: `/job-ads-school`,
                search: location.search,
              }}
            >
              <PlusIcon aria-hidden="true" />
              {t("home.createAd")}
            </PrimaryButton>
          )}
          {status === "success" &&
            (!data.organizationName || !data.phone || !data.location.address) && (
              <LinkButton
                as={Link}
                to={{
                  pathname: `/settings`,
                  search: location.search,
                }}
              >
                {t("home.completeOrganizationInformation")}
              </LinkButton>
            )}

          {user?.organizationPlan !== "PREMIUM" &&
            process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" && (
              <LinkButton
                as={Link}
                to={{
                  pathname: `/pricing`,
                  search: location.search,
                }}
              >
                <PlusCircleIcon aria-hidden="true" />
                {t("home.buy-ads")}
              </LinkButton>
            )}
        </div>
      )}

      <div tw="flex flex-col md:flex-row items-center justify-center rounded-xl gap-20 md:gap-12 mt-16">
        <CardDashboard
          tittle={t("home.new-candidates")}
          data={newCountCandidates >= 0 ? newCountCandidates : 0}
          status={newCountCandidatesStatus}
          tw="bg-green-300 min-h-15 pt-24 pb-14"
          logo={
            <figure tw="absolute w-36 h-36 -top-20 sm:-top-16">
              <img src="/homeIcons/jobook_candidat_matching.svg" alt="" data-ll-status="loaded" />
              <noscript>
                <img src="/homeIcons/jobook_candidat_matching.svg" alt="" />
              </noscript>
            </figure>
          }
        />

        <CardDashboard
          tittle={t("home.active-ads")}
          data={dataCountByStatus ? dataCountByStatus.PUBLISHED : 0}
          status={dataCountByStatusStatus}
          tw="bg-gray-50"
          logo={
            <figure tw="absolute w-44 h-44 -top-20">
              <img
                src="/homeIcons/jobook_publication_offre_emploi_competences.svg"
                alt=""
                data-ll-status="loaded"
              />
              <noscript>
                <img src="/homeIcons/jobook_publication_offre_emploi_competences.svg" alt="" />
              </noscript>
            </figure>
          }
          link={
            <>
              {dataCountByStatus ? (
                <Link
                  to={process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" ? "/job-ads?adStatus=PUBLISHED" : "/job-ads-school?adStatus=PUBLISHED"}
                  tw="flex items-center justify-center text-sm font-normal text-white bg-primary-600 border border-transparent rounded-md w-3/6 py-1 hover:bg-primary-700 shadow-md hover:shadow-lg"
                >
                  {t("home.see")}
                </Link>
              ) : (
                <span tw="h-8"></span>
              )}
            </>
          }
        />

        <CardDashboard
          tittle={t("home.unread-messages")}
          data={unreadMessagesNumber}
          status={unreadMessagesNumberStatus}
          tw="bg-red-300"
          logo={
            <figure tw="absolute w-36 h-36 -top-16">
              <img
                src="/homeIcons/echange_recruteur_candidat.svg"
                className="attachment-medium size-medium lazyloaded"
                alt="Communication entre recruteur et candidats"
                data-ll-status="loaded"
              />
              <noscript>
                <img
                  src="/homeIcons/echange_recruteur_candidat.svg"
                  className="attachment-medium size-medium"
                  alt="Communication entre recruteur et candidats"
                />
              </noscript>
            </figure>
          }
          link={
            <Link
              to="/chat"
              tw="flex items-center justify-center text-sm font-normal text-white bg-primary-600 border border-transparent rounded-md w-3/6 py-1 hover:bg-primary-700 shadow-md hover:shadow-lg"
            >
              {t("home.see")}
            </Link>
          }
        />
      </div>
    </div>
  );
};
